import { useContext } from "react";
import BaseActions, { APIRequestGenerator } from "base/BaseActions";
import APIHelper from "base/APIHelper";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "organization";

const useOrganizationModel = (savedModelInstance = {}) => {
  const { token, Organization } = useContext(UserModel.Context);

  const { getList, getOne } = BaseActions(module, {
    token,
    Organization,
  });

  async function getProductCategories(params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${Organization}/getProductCategories`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        {
          queryParams: params,
        },
        true,
        headers,
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function createProductCategory(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${Organization}/createProductCategory`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        data,
        true,
        headers,
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function updateProductCategory(id, data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${Organization}/updateProductCategory`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        {
          _id: id,
          ...data,
        },
        true,
        headers,
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getList,
    getOne,
    getProductCategories,
    createProductCategory,
    updateProductCategory,
  };
};

const OrganizationModel = BaseModel(useOrganizationModel, "OrganizationModel");

export { useOrganizationModel };
export default OrganizationModel;
