import React from "react";
import { ReactComponent as HomeIcon } from "assets/icons/hoem.svg";
import { ReactComponent as RiskReportIcon } from "assets/icons/riskReport.svg";
import { ReactComponent as UserIcon } from "assets/icons/userGroup.svg";
import { ReactComponent as PointIcon } from "assets/icons/pointManagement.svg";
import { ReactComponent as SystemIcon } from "assets/icons/systemOptions.svg";
import { ReactComponent as CertificateIcon } from "assets/icons/certificate.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/profile.svg";
import { ReactComponent as ReportIcon } from "assets/icons/export.svg";
import { ReactComponent as PointMartIcon } from "assets/icons/pointMart.svg";

import {
  ADMINS,
  CERTIFICATE,
  CONTENT_UPDATE,
  COURSES,
  HOME,
  MY_ACCOUNT,
  POINT_MANAGEMENT,
  RISK_REPORT,
  USERS,
  SAFETY_REASON,
  SITE_AND_ZONE,
  SUB_CONTRACTOR,
  REPORT,
  TRAINING_REPORT,
  STORE_MANAGEMENT,
  ORDER_MANAGEMENT,
  PRODUCT_CATEGORY,
} from "./paths";

const MENU_ITEMS = [
  {
    name: "Home",
    path: HOME,
    icon: <HomeIcon />,
    level: ["organization"],
    resources: ["*"],
  },
  {
    name: "Certificate",
    path: CERTIFICATE,
    icon: <CertificateIcon />,
    level: ["organization"],
    resources: ["*"],
    nestedPath: true,
  },
  {
    name: "RiskManagement",
    path: RISK_REPORT,
    icon: <RiskReportIcon />,
    level: ["organization"],
    resources: ["*"],
    nestedPath: true,
  },
  {
    name: "PointManagement",
    path: POINT_MANAGEMENT,
    icon: <PointIcon />,
    level: ["organization"],
    resources: ["*"],
    nestedPath: true,
  },
  {
    name: "Report",
    path: REPORT,
    icon: <ReportIcon />,
    level: ["organization"],
    resources: ["*"],
    nestedPath: true,
  },
  {
    name: "Users",
    path: USERS,
    icon: <UserIcon />,
    level: ["organization"],
    resources: ["*"],
    nestedPath: true,
  },
  {
    name: "PointMart",
    path: "",
    icon: <PointMartIcon />,
    childrenPath: [],
    children: [
      {
        name: "StoreManagement",
        path: STORE_MANAGEMENT,
        level: ["organization"],
        resources: ["*"],
        nestedPath: true,
      },
      {
        name: "ProductCategory",
        path: PRODUCT_CATEGORY,
        level: ["organization"],
        resources: ["*"],
      },
      {
        name: "OrderManagement",
        path: ORDER_MANAGEMENT,
        level: ["organization"],
        resources: ["*"],
        nestedPath: true,
      },
    ],
  },
  {
    name: "SystemOptions",
    path: "",
    icon: <SystemIcon />,
    childrenPath: [],
    children: [
      {
        name: "Admins",
        path: ADMINS,
        level: ["organization"],
        resources: ["*"],
      },
      {
        name: "SiteAndZone",
        path: SITE_AND_ZONE,
        level: ["organization"],
        resources: ["*"],
      },
      {
        name: "SafetyReason",
        path: SAFETY_REASON,
        level: ["organization"],
        resources: ["*"],
      },
      {
        name: "Courses",
        path: COURSES,
        level: ["organization"],
        resources: ["*"],
      },
      {
        name: "TrainingReport",
        path: TRAINING_REPORT,
        level: ["organization"],
        resources: ["*"],
      },
      {
        name: "ContentUpdate",
        path: CONTENT_UPDATE,
        level: ["organization"],
        resources: ["*"],
      },
      {
        name: "SubContractor",
        path: SUB_CONTRACTOR,
        level: ["organization"],
        resources: ["*"],
      },
    ],
    level: ["organization"],
    resources: ["*"],
  },
  {
    name: "MyAccount",
    path: MY_ACCOUNT,
    icon: <ProfileIcon />,
    level: ["organization"],
    resources: ["*"],
  },
];

export default MENU_ITEMS;
