import { useContext } from "react";
import { APIRequestGenerator } from "base/BaseActions";
import APIHelper from "base/APIHelper";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "serviceEndpoint";

const useServiceEndpointModel = (savedModelInstance = {}) => {
  const { token } = useContext(UserModel.Context);

  async function getProductChangeLogs(id) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/doRequest`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        {
          serviceCode: "eShop",
          endpointCode: "getProductChangeLogs",
          queryParams: {
            _id: id,
          },
        },
        true,
        headers,
      );

      const { records } = response || {};
      const [record = {}] = records || [];

      return Promise.resolve(record);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getCurrencies() {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/doRequest`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        {
          serviceCode: "eShop",
          endpointCode: "getCurrencies",
          pathVariables: {},
          queryParams: {
            key: "bk-reward-point",
          },
          requestBody: {},
          headers: {},
          dryRun: false,
        },
        true,
        headers,
      );

      const { records } = response || {};
      const [record = {}] = records || [];

      return Promise.resolve(record);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getProductChangeLogs,
    getCurrencies,
  };
};

const ServiceEndpointModel = BaseModel(
  useServiceEndpointModel,
  "ServiceEndpointModel",
);

export { useServiceEndpointModel };
export default ServiceEndpointModel;
