import { useContext } from "react";
import { APIRequestGenerator } from "base/BaseActions";
import APIHelper from "base/APIHelper";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "test";

const useTestModel = (savedModelInstance = {}) => {
  const { token } = useContext(UserModel.Context);

  async function getError(data, returnURL = true) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/errorResponse`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        {
          ...data,
          returnURL,
        },
        true,
        headers,
        false,
        !returnURL,
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getError,
  };
};

const TestModel = BaseModel(useTestModel, "TestModel");

export { useTestModel };
export default TestModel;
