import customCaseTable from "./CustomCaseTable";
import moduleTable from "./ModuleTable";

const unKnowError = {
  en: "Unknown Error. Please try again.",
  zh: "發生未知錯誤，請稍後再試",
  zhCHS: "发生未知错误，请稍后再试",
};

function errorTextHelper(responseBody) {
  const { errorCode = "", data = {}, description = "" } = responseBody || {};
  const { ajvError, path = "" } = data || {};

  if (!errorCode) {
    return unKnowError;
  }

  const [module, errorType, customCase] = errorCode.split("-") || [];
  const moduleText = moduleTable(module);
  const customText = customCaseTable(customCase, data);

  // Custom Case
  const last3String = customCase ? customCase.replace(/[a-zA-Z]/g, "") : "";
  if (last3String !== "000" && customText) {
    return customText || description || unKnowError;
  }

  // Authorization
  if (errorType === "A") {
    return "Authorization Error.Please refresh the page and login again.";
  }

  // Unknown / Uncaught Error / Unclassified
  if (errorType === "U") {
    return description || unKnowError;
  }

  // Not Found
  if (errorType === "N") {
    return {
      en: "Record cannot be found. Please try later.",
      zh: "找不到此紀錄，請稍後再試。",
      zhCHS: "找不到此纪录，请稍后再试。",
    };
  }

  // Payment
  if (errorType === "P") {
    return {
      en: "Payment fail,please try again later",
      zh: "付款失敗，請稍後再試",
      zhCHS: "付款失败，请稍后再试",
    };
  }

  // Forbidden / Permission
  if (errorType === "F") {
    return {
      en: `Permission Denied : ${moduleText}`,
      zh: `沒有權限 : ${moduleText}`,
      zhCHS: `没有权限 : ${moduleText}`,
    };
  }

  // Validation
  if (errorType === "V") {
    if (ajvError) {
      const array = ajvError.map((e, i) => {
        const { keyword = "", message = "" } = e || {};

        return `${i + 1}. ${keyword} ${message}\n`;
      });

      return array.toString().replace(/,/g, "");
    }

    if (path) {
      return `Invalid data or Missing required data,\nPath: ${path}`;
    }

    return unKnowError;
  }

  // Business logic
  if (errorType === "B") {
    return description || unKnowError;
  }

  // DB error
  if (errorType === "D") {
    return {
      en: "Service temporarily unavailable, please try again.",
      zh: "系統服務暫時未能提供，請稍候再試。",
      zhCHS: "系统服务暂时未能提供，请稍候再试。",
    };
  }

  return unKnowError;
}

function remarksHelper(responseBody = {}) {
  const { trace = {}, requestLogId = "", errorCode = "" } = responseBody || {};
  const [module, errorType, customCase] = errorCode.split("-") || [];

  const showRequestLogId =
    ["U", "F", "B", "E", "P", "D"].includes(errorType) && requestLogId;
  const showTraceId = trace?.key && trace?.value;

  return `${showTraceId ? `${trace.key} : ${trace.value}` : ""}${
    showRequestLogId && showTraceId ? "\n" : ""
  }${showRequestLogId ? `Request Log Id : ${requestLogId}` : ""}`;
}

function errorResponseGenerator(message, extraData) {
  const { remarks = {}, title = "" } = extraData || {};

  const stringOfRemarks = Object.entries(remarks).reduce(
    (acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      return acc.concat("", `${key} : ${value}\n`);
    },
    "",
  );

  return {
    description: message,
    errorCode: "frontEndError",
    remarks: typeof remarks === "string" ? remarks : stringOfRemarks,
    title,
  };
}

export { errorResponseGenerator, errorTextHelper, remarksHelper };
