export default function ModuleTable(module) {
  const digitOnly = module.replace(/[a-zA-Z]/g, "");
  switch (digitOnly) {
    case "000":
      return "General";
    case "001":
      return "Organization";
    case "002":
      return "Auth";
    case "003":
      return "CronConfig";
    case "004":
      return "CronLog";
    case "005":
      return "RequestLog";
    case "006":
      return "Transmission";
    case "007":
      return "Media";
    case "008":
      return "Report";
    case "009":
      return "OTP";
    case "0010":
      return "ConstructionSite";
    case "011":
      return "Zone";
    case "012":
      return "Document";
    case "013":
      return "OCR";
    case "014":
      return "Notification";
    case "015":
      return "SubContractor";
    case "016":
      return "Profile";
    case "017":
      return "User";
    case "018":
      return "UserGroup";
    case "019":
      return "PointCategory";
    case "020":
      return "PointItem";
    case "021":
      return "PointTransaction";
    case "022":
      return "Wallet";
    case "023":
      return "Service";
    case "024":
      return "ServiceEndpoint";
    case "025":
      return "JobTitle";
    case "026":
      return "RiskReport";
    case "027":
      return "Course";
    case "028":
      return "Certificate";
    case "029":
      return "Configuration";

    case "300":
      return "General";
    case "301":
      return "System";
    case "302":
      return "Auth";
    case "303":
      return "CronConfig";
    case "304":
      return "CronLog";
    case "305":
      return "RequestLog";
    case "306":
      return "TransmissionLog";
    case "307":
      return "Media";
    case "308":
      return "Report";
    case "320":
      return "Account";
    case "321":
      return "AccountItem";
    case "322":
      return "AccountType";
    case "323":
      return "Owner";
    case "324":
      return "Organization";
    case "325":
      return "Transaction";
    case "326":
      return "TransactionCallback";
    case "327":
      return "TransactionRequest";

    default:
      return "";
  }
}
