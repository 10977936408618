import {
  errorResponseGenerator,
  errorTextHelper,
  remarksHelper,
} from "base/ErrorHelper";
import { useTranslation } from "react-i18next";
import { getMultiLangProperty } from "base/BaseHelper";

export default function useHandler(TOAST, ALERT) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  function toastHandler(errorMsg = "", type = "error") {
    if (!errorMsg) {
      return;
    }
    if (typeof errorMsg === "object") {
      TOAST[type](errorMsg?.title, errorMsg?.text);
      return;
    }

    TOAST[type](errorMsg);
  }

  function isErrorWithErrorCode(error) {
    return (
      typeof error === "object" &&
      error !== null &&
      "errorCode" in error &&
      typeof error.errorCode === "string"
    );
  }

  function isErrorWithMessage(error) {
    return (
      typeof error === "object" &&
      error !== null &&
      "message" in error &&
      typeof error.message === "string"
    );
  }

  function isHTMLError(error) {
    return (
      typeof error === "object" &&
      error !== null &&
      "errorCode" in error &&
      typeof error.errorCode === "string" &&
      error.errorCode === "htmlError"
    );
  }

  function convertErrorBody(expectedError) {
    try {
      if (isHTMLError(expectedError)) {
        return process.env.REACT_APP_ENV === "dev"
          ? errorResponseGenerator(JSON.stringify(expectedError))
          : errorResponseGenerator(t("unKnowError"));
      }

      if (isErrorWithErrorCode(expectedError)) return expectedError;

      if (isErrorWithMessage(expectedError)) {
        return errorResponseGenerator(expectedError?.message || "");
      }

      if (typeof expectedError === "string") {
        return errorResponseGenerator(expectedError);
      }

      throw expectedError;
    } catch (error) {
      if (typeof error === "string") {
        return errorResponseGenerator(error);
      }

      if (isErrorWithMessage(error)) {
        return errorResponseGenerator(error?.message || "");
      }

      errorResponseGenerator(t("unKnowError"));
    }
  }

  function errorHandler(error = {}, inputProps = {}) {
    const responseBody = convertErrorBody(error) || {};

    const alertProps = {
      title: "Error",
      text: "",
      showCloseButton: false,
      allowOutsideClick: false,
      remarks: "",
      ...inputProps,
    };

    // Front End Error
    if (responseBody?.errorCode === "frontEndError") {
      alertProps.text = responseBody?.description;
      alertProps.title = responseBody?.title || t("Error");
      alertProps.remarks = responseBody?.remarks;
      ALERT.error(alertProps);
      return;
    }

    // v2 format
    const { errorCode = "" } = responseBody || {};
    const errorText = errorTextHelper(responseBody);
    const multiLangText =
      getMultiLangProperty(errorText, language) || errorText;
    const remarks = remarksHelper(responseBody);

    alertProps.title = `${t("Error")} (${errorCode})`;
    alertProps.text = multiLangText;
    alertProps.remarks = remarks;

    ALERT.error(alertProps);
  }

  return {
    toastHandler,
    errorHandler,
  };
}
