export default function customCaseTable(customCase) {
  switch (customCase) {
    case "BUK001":
      return {
        en: "Your account has been suspended. You cannot access the application until the suspension is lifted. Please contact the site admin for further assistance.",
        zh: "您的帳戶已被暫停。您無法訪問應用程式，直到暫停解除。請尋求地盤行政人員的幫助。",
        zhCHS:
          "您的账户已被暂停。您无法访问应用程序，直到暂停解除。请寻求工地行政人员的帮助。",
      };
    case "BUK002":
      return {
        en: "Permission checking: Failed user status checking",
        zh: "Permission checking: Failed user status checking",
        zhCHS: "",
      };
    case "BUK003":
    case "BUK004":
    case "BUK013":
    case "BUK014":
      return {
        en: "An unknown error has occurred. Please try again—if the problem persists, contact the site admin for further assistance.",
        zh: "發生未知錯誤。請重試—如果問題仍然存在，請尋求地盤行政人員的幫助。",
        zhCHS:
          "发生未知错误。请重试—如果问题仍然存在，请寻求工地行政人员的帮助。",
      };
    case "BUK005":
      return {
        en: "Permission checking: User attempt to access records that are out of his/her permission level",
        zh: "Permission checking: User attempt to access records that are out of his/her permission level",
        zhCHS: "",
      };
    case "BUK006":
      return {
        en: "Login: Organization code not match",
        zh: "Login: Organization code not match",
        zhCHS: "",
      };
    case "BUK007":
      return {
        en: "Login: Username not found",
        zh: "Login: Username not found",
        zhCHS: "",
      };
    case "BUK008":
      return {
        en: "Login: Password not match",
        zh: "Login: Password not match",
        zhCHS: "",
      };
    case "BUK009":
      return {
        en: "OTP: OTP refCode not found",
        zh: "OTP: OTP refCode not found",
        zhCHS: "",
      };
    case "BUK010":
      return {
        en: "OTP expired. Request a new one and try again.",
        zh: "一次性密碼已過期。請請求新的一次性密碼並重試。",
        zhCHS: "一次性密码已过期。请请求新的一次性密码并重试。",
      };
    case "BUK011":
      return {
        en: "Maximum OTP attempts reached. Please try again later.",
        zh: "已達到一次性密碼嘗試次數上限。請稍後再試。",
        zhCHS: "已达到一次性密码尝试次数上限。请稍后再试。",
      };
    case "BUK012":
      return {
        en: "Incorrect OTP. Please check and try again.",
        zh: "一次性密碼錯誤。請檢查並重試。",
        zhCHS: "一次性密码错误。请检查并重试。",
      };
    case "BUK015":
      return {
        en: "Invalid document / The document cannot be recognized. Please resubmit.",
        zh: "文件無效/無法識別該文件。請重新提交。",
        zhCHS: "文件无效/无法识别该文件。请重新提交。",
      };
    case "BUK016":
      return {
        en: "Reset Password: User cannot be retrieved from otp record",
        zh: "Reset Password: User cannot be retrieved from otp record",
        zhCHS: "",
      };
    case "BUK017":
      return {
        en: "Reset Pasword: Auth record not found",
        zh: "Reset Pasword: Auth record not found",
        zhCHS: "",
      };
    case "BUK018":
      return {
        en: "Construction Site: There is exising profile using the deleted site",
        zh: "Construction Site: There is exising profile using the deleted site",
        zhCHS: "",
      };
    case "BUK019":
      return {
        en: "Zone: There is exising profile using the deleted zone",
        zh: "Zone: There is exising profile using the deleted zone",
        zhCHS: "",
      };
    case "BUK020":
      return {
        en: "Course: There is existing certificate using the deleted course",
        zh: "Course: There is existing certificate using the deleted course",
        zhCHS: "",
      };
    case "BUK021":
      return {
        en: "Job Title: There is existing user using the deleted job title",
        zh: "Job Title: There is existing user using the deleted job title",
        zhCHS: "",
      };
    case "BUK022":
      return {
        en: "Point Category: There is existing point item using the deleted category",
        zh: "Point Category: There is existing point item using the deleted category",
        zhCHS: "",
      };
    case "BUK023":
      return {
        en: "Point Item: There is existing point transaction using the deleted item",
        zh: "Point Item: There is existing point transaction using the deleted item",
        zhCHS: "",
      };
    case "BUK024":
      return {
        en: "Sub Contractor: There is existing user using the deleted sub contractor",
        zh: "Sub Contractor: There is existing user using the deleted sub contractor",
        zhCHS: "",
      };
    case "BUK025":
      return {
        en: "Document: OCR is not enabled",
        zh: "Document: OCR is not enabled",
        zhCHS: "",
      };

    case "BUK026":
      return {
        en: "Invalid document / The document cannot be recognized. Please resubmit.",
        zh: "文件無效/無法識別該文件。請重新提交。",
        zhCHS: "文件无效/无法识别该文件。请重新提交。",
      };
    case "BUK027":
      return {
        en: "Document: Document type does not support OCR",
        zh: "Document: Document type does not support OCR",
        zhCHS: "",
      };
    case "BUK028":
      return {
        en: "The role has already been updated/ confirmed.",
        zh: "角色已被更新/確認。",
        zhCHS: "角色已被更新/确认。",
      };
    case "BUK029":
      return {
        en: "Document: Only unknown and pending user can delete all their documents",
        zh: "Document: Only unknown and pending user can delete all their documents",
        zhCHS: "",
      };
    case "BUK030":
      return {
        en: "Service Endpoint: Minimum user level not reached",
        zh: "Service Endpoint: Minimum user level not reached",
        zhCHS: "",
      };
    case "BUK031":
      return {
        en: "User: Non active/admin user attempt to update other user's info",
        zh: "User: Non active/admin user attempt to update other user's info",
        zhCHS: "",
      };
    case "BUK032":
      return {
        en: "Wallet: Personal level user attempt to access other user's info",
        zh: "Wallet: Personal level user attempt to access other user's info",
        zhCHS: "",
      };
    case "BUK033":
      return {
        en: "User Group: There is existing user using the deleted user group",
        zh: "User Group: There is existing user using the deleted user group",
        zhCHS: "",
      };
    case "BUK034":
      return {
        en: "User Group: At least one user group for each role",
        zh: "User Group: At least one user group for each role",
        zhCHS: "",
      };
    case "BUK035":
    case "BUK040":
      return {
        en: "System busy. Please try again later.",
        zh: "系統繁忙。請稍後再試。",
        zhCHS: "系统繁忙。请稍后再试。",
      };
    case "BUK036":
      return { en: "", zh: "" };
    case "BUK037":
      return {
        en: "User: change password of another user",
        zh: "User: change password of another user",
        zhCHS: "",
      };
    case "BUK038":
      return { en: "", zh: "" };
    case "BUK039":
      return {
        en: "Configuration: No organization id is provided",
        zh: "Configuration: No organization id is provided",
        zhCHS: "",
      };
    case "BUK041":
      return {
        en: "Permission checking: Personal level user attempts to access records belong to another user",
        zh: "Permission checking: Personal level user attempts to access records belong to another user",
        zhCHS: "",
      };
    case "BUK042":
    case "BUK043":
    case "BUK044":
    case "BUK045":
    case "BUK048":
      return {
        en: "You don't have access to this site or zone. Please contact the site admin for further assistance.",
        zh: "您無權訪問此網站或區域。請聯繫地盤行政人員以獲取進一步幫助。",
        zhCHS: "您无权访问此网站或区域。请联系工地行政人员以获取进一步帮助。",
      };

    case "BUK046":
      return {
        en: "Document: OCR is not enabled when trying to get ocr result",
        zh: "Document: OCR is not enabled when trying to get ocr result",
        zhCHS: "",
      };
    case "BUK047":
      return {
        en: "Invalid document / The document cannot be recognized. Please resubmit.",
        zh: "文件無效/無法識別該文件。請重新提交。",
        zhCHS: "文件无效/无法识别该文件。请重新提交。",
      };
    case "BUK049":
      return {
        en: "Permission checking: users attemps to create/edit records outside their organization",
        zh: "Permission checking: users attemps to create/edit records outside their organization",
        zhCHS:
          "Permission checking: users attemps to create/edit records outside their organization",
      };
    case "BUK050":
      return {
        en: "User: Duplicated phone number with existing user",
        zh: "User: Duplicated phone number with existing user",
        zhCHS: "User: Duplicated phone number with existing user",
      };
    case "BUK051":
      return {
        en: "The submitted HKID already has an existing account associated with it.",
        zh: "提交的香港身份證已經有一個現有帳戶與之關聯。",
        zhCHS: "提交的香港身份证已经有一个现有账户与之关联。",
      };
    // case "BUK052":
    //   return {
    //     en: "User: Unmatched CISTC chinese name with HKID chinese name",
    //     zh: "User: Unmatched CISTC chinese name with HKID chinese name",
    //     zhCHS: "User: Unmatched CISTC chinese name with HKID chinese name",
    //   };
    // case "BUK053":
    //   return {
    //     en: "User: Unmatched CISTC english name with HKID english name",
    //     zh: "User: Unmatched CISTC english name with HKID english name",
    //     zhCHS: "User: Unmatched CISTC english name with HKID english name",
    //   };
    case "BUK052":
      return {
        en: "User: Country code not accepted",
        zh: "User: Country code not accepted",
        zhCHS: "User: Country code not accepted",
      };
    case "BUK053":
      return {
        en: "User: Country code not accepted",
        zh: "User: Country code not accepted",
        zhCHS: "User: Country code not accepted",
      };
    case "BUK054":
      return {
        en: "Auth: Cannot remove standard login method",
        zh: "Auth: Cannot remove standard login method",
        zhCHS: "Auth: Cannot remove standard login method",
      };
    case "BUK055":
      return {
        en: "Auth: Token's aud not match provider's audience",
        zh: "Auth: Token's aud not match provider's audience",
        zhCHS: "Auth: Token's aud not match provider's audience",
      };
    case "BUK056":
      return {
        en: "Auth: Token's iss not match provider's issuer",
        zh: "Auth: Token's iss not match provider's issuer",
        zhCHS: "Auth: Token's iss not match provider's issuer",
      };
    case "BUK057":
      return {
        en: "Auth: Missing appid in token",
        zh: "Auth: Missing appid in token",
        zhCHS: "Auth: Missing appid in token",
      };
    case "BUK058":
      return {
        en: "Auth: Token expired",
        zh: "Auth: Token expired",
        zhCHS: "Auth: Token expired",
      };
    case "BUK059":
      return {
        en: "Auth: Failed to retrieve profile from provider",
        zh: "Auth: Failed to retrieve profile from provider",
        zhCHS: "Auth: Failed to retrieve profile from provider",
      };
    case "BUK060":
      return {
        en: "Auth: Merchant configuration not found",
        zh: "Auth: Merchant configuration not found",
        zhCHS: "Auth: Merchant configuration not found",
      };
    case "BUK061":
      return {
        en: "Auth: Token not exists",
        zh: "Auth: Token not exists",
        zhCHS: "Auth: Token not exists",
      };
    case "BUK062":
      return {
        en: "Auth: Token's issuer not found/support",
        zh: "Auth: Token's issuer not found/support",
        zhCHS: "Auth: Token's issuer not found/support",
      };
    case "BUK063":
      return {
        en: "Auth: Token's provider not found/support",
        zh: "Auth: Token's provider not found/support",
        zhCHS: "Auth: Token's provider not found/support",
      };
    case "BUK064":
      return {
        en: "The account is logged out, please login again.",
        zh: "你已被登出，請重新登入。",
        zhCHS: "你已被登出，请重新登入。",
      };
    case "BUK065":
      return {
        en: "Auth: Failed to verify token",
        zh: "Auth: Failed to verify token",
        zhCHS: "Auth: Failed to verify token",
      };
    case "BUK066":
      return {
        en: "Auth: Oauth ac already linked up with another user",
        zh: "Auth: Oauth ac already linked up with another user",
        zhCHS: "Auth: Oauth ac already linked up with another user",
      };
    case "BUK067":
      return {
        en: "User: Invalid phone number",
        zh: "User: Invalid phone number",
        zhCHS: "User: Invalid phone number",
      };
    case "BUK068":
      return {
        en: "Incorrect OTP. Please check and try again.",
        zh: "一次性密碼錯誤。請檢查並重試。",
        zhCHS: "一次性密码错误。请检查并重试。",
      };
    case "BUK069":
      return {
        en: "User: Phone number not match with OTP",
        zh: "User: Phone number not match with OTP",
        zhCHS: "User: Phone number not match with OTP",
      };
    case "BUK070":
      return {
        en: "Invalid role change",
        zh: "Invalid role change",
        zhCHS: "Invalid role change",
      };
    case "BUK071":
      return {
        en: "Worker is not assigned to selected site and zone. Please check again.",
        zh: "你選擇的事發工地跟工友所屬的不符，請檢查。",
        zhCHS: "你选择的事发工地跟工友所属的不符，请检查。",
      };
    case "BUK072":
      return {
        en: "Point Transaction: Unmatched site and zone",
        zh: "Point Transaction: Unmatched site and zone",
        zhCHS: "Point Transaction: Unmatched site and zone",
      };
    case "BUK073":
      return {
        en: "Safety Admin is not entitled to record points for selected site.",
        zh: "安全人員無權在此場地新增記分/積分。",
        zhCHS: "安全人员无权在此场地新增记分/积分。",
      };
    case "BUK074":
      return {
        en: "Point Transaction: Unmatched sub contractor",
        zh: "Point Transaction: Unmatched sub contractor",
        zhCHS: "Point Transaction: Unmatched sub contractor",
      };
    case "BUK075":
      return {
        en: "Point Transaction: Construction site is required when creat safety point transaction",
        zh: "Point Transaction: Construction site is required when creat safety point transaction",
        zhCHS:
          "Point Transaction: Construction site is required when creat safety point transaction",
      };
    case "BUK076":
      return {
        en: "Notification Subscription: Organization is not registered on Notification Service",
        zh: "Notification Subscription: Organization is not registered on Notification Service",
        zhCHS:
          "Notification Subscription: Organization is not registered on Notification Service",
      };
    case "BUK077":
      return {
        en: "Notification Subscription: Provider is not registered on Notification Service",
        zh: "Notification Subscription: Provider is not registered on Notification Service",
        zhCHS:
          "Notification Subscription: Provider is not registered on Notification Service",
      };
    case "BUK078":
      return {
        en: "Notification Subscription: Account is not registered on Notification Service",
        zh: "Notification Subscription: Account is not registered on Notification Service",
        zhCHS:
          "Notification Subscription: Account is not registered on Notification Service",
      };
    case "BUK079":
      return {
        en: "Notification Subscription: Duplicated subscription",
        zh: "Notification Subscription: Duplicated subscription",
        zhCHS: "Notification Subscription: Duplicated subscription",
      };
    case "BUK080":
      return {
        en: "Notification Subscription: Invalid topics for selected type",
        zh: "Notification Subscription: Invalid topics for selected type",
        zhCHS: "Notification Subscription: Invalid topics for selected type",
      };

    case "BUK081":
      return {
        en: "Certificate: Missing ConstructionSite when training report is selected",
        zh: "Certificate: Missing ConstructionSite when training report is selected",
        zhCHS:
          "Certificate: Missing ConstructionSite when training report is selected",
      };
    case "BUK082":
      return {
        en: "Point Category: Duplicated slug",
        zh: "Point Category: Duplicated slug",
        zhCHS: "Point Category: Duplicated slug",
      };
    case "BUK083":
      return {
        en: "Construction Site: Site is not created on eShop service",
        zh: "Construction Site: Site is not created on eShop service",
        zhCHS: "Construction Site: Site is not created on eShop service",
      };
    case "BUK084":
      return {
        en: "Organization: Organization is not created on eShop service",
        zh: "Organization: Organization is not created on eShop service",
        zhCHS: "Organization: Organization is not created on eShop service",
      };
    case "BUK085":
      return {
        en: "Organization: Organization is not created on credit wallet service",
        zh: "Organization: Organization is not created on credit wallet service",
        zhCHS:
          "Organization: Organization is not created on credit wallet service",
      };
    case "BUK086":
      return {
        en: "User: Invalid QR Code",
        zh: "User: Invalid QR Code",
        zhCHS: "User: Invalid QR Code",
      };
    case "BUK087":
      return {
        en: "System: Maintenance mode is on",
        zh: "System: Maintenance mode is on",
        zhCHS: "System: Maintenance mode is on",
      };
    case "BUK088":
      return {
        en: "Wallet: Owner is not created on credit wallet service ",
        zh: "Wallet: Owner is not created on credit wallet service ",
        zhCHS: "Wallet: Owner is not created on credit wallet service ",
      };
    case "BUK089":
      return {
        en: "Permission: Invalid service domain",
        zh: "Permission: Invalid service domain",
        zhCHS: "Permission: Invalid service domain",
      };
    case "BUK090":
      return {
        en: "New app version is available. Please go to Google Play Store/App Store to update your app.",
        zh: "發現了新的應用程式版本，請到Google Play Store/App Store更新你的應用程式。",
        zhCHS:
          "发现了新的应用程式版本，请到Google Play Store/App Store更新你的应用程式。",
      };

    /* ================================================================ E-Shop-Service ===================================================================================================== */

    case "ESS001":
      return {
        en: "Permission checking: Suspended user",
        zh: "Permission checking: Suspended user",
        zhChS: "Permission checking: Suspended user",
      };
    case "ESS002":
      return {
        en: "Permission checking: Failed user group permission checking",
        zh: "Permission checking: Failed user group permission checking",
        zhChS: "Permission checking: Failed user group permission checking",
      };
    case "ESS003":
      return {
        en: "Permission checking: Failed system permission checking",
        zh: "Permission checking: Failed system permission checking",
        zhChS: "Permission checking: Failed system permission checking",
      };
    case "ESS004":
      return {
        en: "Login: Organization code not match",
        zh: "Login: Organization code not match",
        zhChS: "Login: Organization code not match",
      };
    case "ESS005":
      return {
        en: "Login: Incorrect username or password",
        zh: "Login: Incorrect username or password",
        zhChS: "Login: Incorrect username or password",
      };
    case "ESS006":
      return {
        en: "OTP: OTP not found",
        zh: "OTP: OTP not found",
        zhChS: "OTP: OTP not found",
      };
    case "ESS007":
      return {
        en: "OTP: Maximum OTP failed attempts reached",
        zh: "OTP: Maximum OTP failed attempts reached",
        zhChS: "OTP: Maximum OTP failed attempts reached",
      };
    case "ESS008":
      return {
        en: "OTP: OTP code not match",
        zh: "OTP: OTP code not match",
        zhChS: "OTP: OTP code not match",
      };
    case "ESS009":
      return {
        en: "Login: User cannot be retrieved from auth/otp record",
        zh: "Login: User cannot be retrieved from auth/otp record",
        zhChS: "Login: User cannot be retrieved from auth/otp record",
      };
    case "ESS010":
      return {
        en: "Login: Auth record not found",
        zh: "Login: Auth record not found",
        zhChS: "Login: Auth record not found",
      };
    case "ESS011":
      return {
        en: "Reset Password: User cannot be retrieved from otp record",
        zh: "Reset Password: User cannot be retrieved from otp record",
        zhChS: "Reset Password: User cannot be retrieved from otp record",
      };
    case "ESS012":
      return {
        en: "Reset Pasword: Auth record not found",
        zh: "Reset Pasword: Auth record not found",
        zhChS: "Reset Pasword: Auth record not found",
      };
    case "ESS013":
      return {
        en: "Service Endpoint: Minimum user level not reached",
        zh: "Service Endpoint: Minimum user level not reached",
        zhChS: "Service Endpoint: Minimum user level not reached",
      };
    case "ESS014":
      return {
        en: "User Group: There is existing user using the deleted user group",
        zh: "User Group: There is existing user using the deleted user group",
        zhChS:
          "User Group: There is existing user using the deleted user group",
      };
    case "ESS015":
      return {
        en: "User Group: At least one user group for each role",
        zh: "User Group: At least one user group for each role",
        zhChS: "User Group: At least one user group for each role",
      };
    case "ESS016":
      return {
        en: "User: Only CMS Admin and Site Admin can remove users",
        zh: "User: Only CMS Admin and Site Admin can remove users",
        zhChS: "User: Only CMS Admin and Site Admin can remove users",
      };
    case "ESS017":
      return {
        en: "Auth: Email of oauth account not match user email",
        zh: "Auth: Email of oauth account not match user email",
        zhChS: "Auth: Email of oauth account not match user email",
      };
    case "ESS018":
      return {
        en: "Auth: Cannot remove standard login method",
        zh: "Auth: Cannot remove standard login method",
        zhChS: "Auth: Cannot remove standard login method",
      };
    case "ESS019":
      return {
        en: "Auth: Token's aud not match provider's audience",
        zh: "Auth: Token's aud not match provider's audience",
        zhChS: "Auth: Token's aud not match provider's audience",
      };
    case "ESS020":
      return {
        en: "Auth: Token's iss not match provider's issuer",
        zh: "Auth: Token's iss not match provider's issuer",
        zhChS: "Auth: Token's iss not match provider's issuer",
      };
    case "ESS021":
      return {
        en: "Auth: Missing appid in token",
        zh: "Auth: Missing appid in token",
        zhChS: "Auth: Missing appid in token",
      };
    case "ESS022":
      return {
        en: "Auth: Token expired",
        zh: "Auth: Token expired",
        zhChS: "Auth: Token expired",
      };
    case "ESS023":
      return {
        en: "Auth: Failed to retrieve profile from provider",
        zh: "Auth: Failed to retrieve profile from provider",
        zhChS: "Auth: Failed to retrieve profile from provider",
      };
    case "ESS024":
      return {
        en: "Auth: Merchant configuration not found",
        zh: "Auth: Merchant configuration not found",
        zhChS: "Auth: Merchant configuration not found",
      };
    case "ESS025":
      return {
        en: "Auth: Token's issuer not found/support",
        zh: "Auth: Token's issuer not found/support",
        zhChS: "Auth: Token's issuer not found/support",
      };
    case "ESS026":
      return {
        en: "Auth: Token's provider not found/support",
        zh: "Auth: Token's provider not found/support",
        zhChS: "Auth: Token's provider not found/support",
      };
    case "ESS027":
      return {
        en: "Auth: Auth record not found",
        zh: "Auth: Auth record not found",
        zhChS: "Auth: Auth record not found",
      };
    case "ESS028":
      return {
        en: "Auth: Failed to verify token",
        zh: "Auth: Failed to verify token",
        zhChS: "Auth: Failed to verify token",
      };
    case "ESS029":
      return {
        en: "Auth: Oauth ac already linked up with another user",
        zh: "Auth: Oauth ac already linked up with another user",
        zhChS: "Auth: Oauth ac already linked up with another user",
      };
    case "ESS030":
      return {
        en: "Cart: Some product is missing",
        zh: "Cart: Some product is missing",
        zhChS: "Cart: Some product is missing",
      };
    case "ESS031":
      return {
        en: "Cart: Cart product contain 2 or more store while 1 or more store require exclusive product",
        zh: "Cart: Cart product contain 2 or more store while 1 or more store require exclusive product",
        zhChS:
          "Cart: Cart product contain 2 or more store while 1 or more store require exclusive product",
      };
    case "ESS032":
      return {
        en: "OrderRequest: Create without user",
        zh: "OrderRequest: Create without user",
        zhChS: "OrderRequest: Create without user",
      };
    case "ESS033":
      return {
        en: "OrderRequest: Some product is missing",
        zh: "OrderRequest: Some product is missing",
        zhChS: "OrderRequest: Some product is missing",
      };
    case "ESS034":
      return {
        en: "OrderRequest:  Some product have ran out of stock",
        zh: "OrderRequest:  Some product have ran out of stock",
        zhChS: "OrderRequest:  Some product have ran out of stock",
      };
    case "ESS035":
      return {
        en: "OrderRequest: Some product is not available",
        zh: "OrderRequest: Some product is not available",
        zhChS: "OrderRequest: Some product is not available",
      };
    case "ESS036":
      return {
        en: "OrderRequest: Some product is missing store",
        zh: "OrderRequest: Some product is missing store",
        zhChS: "OrderRequest: Some product is missing store",
      };
    case "ESS037":
      return {
        en: "OrderRequest: Invalid Quantity",
        zh: "OrderRequest: Invalid Quantity",
        zhChS: "OrderRequest: Invalid Quantity",
      };
    case "ESS038":
      return {
        en: "OrderRequest: Order contain multiple orginzation",
        zh: "OrderRequest: Order contain multiple orginzation",
        zhChS: "OrderRequest: Order contain multiple orginzation",
      };
    case "ESS039":
      return {
        en: "OrderRequest: Product contain 2 or more store while 1 or more store require exclusive product",
        zh: "OrderRequest: Product contain 2 or more store while 1 or more store require exclusive product",
        zhChS:
          "OrderRequest: Product contain 2 or more store while 1 or more store require exclusive product",
      };
    case "ESS040":
      return {
        en: "OrderRequest: Not enough stock remaining",
        zh: "OrderRequest: Not enough stock remaining",
        zhChS: "OrderRequest: Not enough stock remaining",
      };
    case "ESS041":
      return {
        en: "Order: Delivery Order is not confirmed",
        zh: "Order: Delivery Order is not confirmed",
        zhChS: "Order: Delivery Order is not confirmed",
      };
    case "ESS042":
      return {
        en: "Order: Order has no items",
        zh: "Order: Order has no items",
        zhChS: "Order: Order has no items",
      };
    case "ESS043":
      return {
        en: "Order: Delivery Order is not paid",
        zh: "Order: Delivery Order is not paid",
        zhChS: "Order: Delivery Order is not paid",
      };
    case "ESS044":
      return {
        en: "Order: Cancel Order is already cancelled",
        zh: "Order: Cancel Order is already cancelled",
        zhChS: "Order: Cancel Order is already cancelled",
      };
    case "ESS045":
      return {
        en: "ProductChangeLog: Stock cannot be negative",
        zh: "ProductChangeLog: Stock cannot be negative",
        zhChS: "ProductChangeLog: Stock cannot be negative",
      };
    case "ESS046":
      return {
        en: "Transaction: Organization does not match",
        zh: "Transaction: Organization does not match",
        zhChS: "Transaction: Organization does not match",
      };
    case "ESS047":
      return {
        en: "Transaction: Order already paid",
        zh: "Transaction: Order already paid",
        zhChS: "Transaction: Order already paid",
      };
    case "ESS048":
      return {
        en: "Transaction: Paiding a currency that was not found in order",
        zh: "Transaction: Paiding a currency that was not found in order",
        zhChS: "Transaction: Paiding a currency that was not found in order",
      };
    case "ESS049":
      return {
        en: "Transaction: None of the store support this payment method",
        zh: "Transaction: None of the store support this payment method",
        zhChS: "Transaction: None of the store support this payment method",
      };

    default:
      return "";
  }
}
