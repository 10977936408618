import React from "react";
import cmsAdminM from "assets/images/cmsAdmin.png";
import cmsAdminF from "assets/images/cmsAdmin_f.png";
import managerM from "assets/images/manager.png";
import managerF from "assets/images/manager_f.png";
import safetyAdminM from "assets/images/safetyAdmin.png";
import safetyAdminF from "assets/images/safetyAdmin_f.png";
import siteAdminM from "assets/images/siteAdmin.png";
import siteAdminF from "assets/images/siteAdmin_f.png";
import unknown from "assets/images/unknow.png";
import workerM from "assets/images/worker.png";
import workerF from "assets/images/worker_f.png";
import { getFullName, getMultiLangProperty } from "base/BaseHelper";
import { FlexDiv, StyledP } from "base/BaseStyle";
import { useTranslation } from "react-i18next";
import {
  CMS_ADMIN,
  MANAGER,
  SAFETY_ADMIN,
  SITE_ADMIN,
  WORKER,
} from "constants/base";
import StatusTag from "../StatusTag";
import { IconContainer } from "./Avatar.styles";

const iconList = {
  [MANAGER]: {
    f: managerF,
    m: managerM,
  },
  [SAFETY_ADMIN]: {
    f: safetyAdminF,
    m: safetyAdminM,
  },
  [SITE_ADMIN]: {
    f: siteAdminF,
    m: siteAdminM,
  },
  [WORKER]: {
    f: workerF,
    m: workerM,
  },
  [CMS_ADMIN]: {
    f: cmsAdminF,
    m: cmsAdminM,
  },
};

function Avatar({
  user = {},
  organizationData,
  showPhone = true,
  showStatus,
  nameDirection = "column",
  sm,
  displayRole,
}) {
  const { i18n } = useTranslation();
  const {
    role,
    phone,
    SubContractor,
    status,
    gender = "f",
    UserGroup,
  } = user || {};
  return (
    <FlexDiv style={{ width: "100%" }}>
      <FlexDiv $gap={sm ? 10 : 20} style={{ width: "100%" }}>
        <IconContainer $sm={sm}>
          {role && iconList?.[role] ? (
            <img
              src={gender ? iconList?.[role]?.[gender] : iconList?.[role].m}
              alt="account"
            />
          ) : (
            <img src={unknown} alt="account" />
          )}
        </IconContainer>
        <FlexDiv direction="column" $alignItems="flex-start">
          <FlexDiv
            direction={nameDirection}
            $alignItems="flex-start"
            $gap={nameDirection === "row" ? 10 : 0}
          >
            <StyledP fontSize={sm ? 14 : 18} $fontWeight="500" color="primary">
              {UserGroup?._id
                ? getMultiLangProperty(UserGroup?.name, i18n.language)
                : ""}
              {displayRole &&
                ` (${getMultiLangProperty(displayRole?.name, i18n.language)})`}
            </StyledP>
            <StyledP fontSize={sm ? 16 : 18} $fontWeight="500">
              {getFullName(user, i18n.language)}
            </StyledP>
          </FlexDiv>
          <StyledP fontSize={sm ? 14 : 16} color={sm ? "gray" : "black"}>
            {organizationData?._id
              ? getMultiLangProperty(organizationData?.name, i18n.language)
              : getMultiLangProperty(SubContractor?.name, i18n.language)}
          </StyledP>
          {showPhone && (
            <StyledP fontSize={sm ? 14 : 16} color="gray">
              {phone?.number}
            </StyledP>
          )}
        </FlexDiv>
      </FlexDiv>
      {showStatus && status && <StatusTag status={status} />}
    </FlexDiv>
  );
}

export default Avatar;
