export default {
  status: "狀態",
  updatedDate: "最後更新",
  day: "天",
  hour: "小時",
  minute: "分鐘",
  safetyPoint: "安全記分",
  rewardPoint: "獎勵記分",
  createdAt: "創建日期",
  updatedAt: "最後更新",
  stockStatus: "Stock Status",
  followUpStatus: "跟進狀態",
  editStatus: "編輯跟進狀態",
  Error: "Error",
  unKnowError: "發生未知錯誤，請稍後再試",

  Popup: {
    created: "已新增紀錄",
    updated: "已更新紀錄",
    deleted: "已刪除紀錄",
    uploaded: "已上傳紀錄",
    imported: "已導入紀錄",
    generated: "已生成資料",
    sent: "已發出短訊",
    canceled: "預約已取消",
    confirmResent: "確認信訊已重新發出",
    terminated: "預約已終止",
    unsaved: "未儲存更改",
    leaveWithoutSaving: "不儲存並離開",
    confirmDelete: "確定刪除紀錄?",
    areYouSure: "是否確定？",
    lostDataWarning:
      "您將在沒有保存的情況下離開此頁面。\n所有變更都將丟失。\n您確定不保存就離開嗎？",
    bathUploadFailed: "第 {{number}} 個文件上傳失敗，請重試。",
    unKnow: "發生未知錯誤，請稍後再試",
    getResponseTimeout: "Request Timeout, Please try again later",
  },
  Button: {
    save: "儲存",
    update: "更新",
    close: "關閉",
    cancel: "取消",
    reset: "重置",
    search: "搜尋",
    delete: "刪除",
    confirm: "確認",
    add: "新增",
    back: "返回",
    refresh: "重新整理",
    redirect: "導向至網址",
    logout: "登出",
    downloadCSV: "下載CSV",
    download: "Download",
  },
  Status: {
    active: "有效",
    inactive: "無效",
    pending: "未處理",
    pendingApproval: "待審批",
    suspended: "未啟用",
    completed: "已完成",
    rejected: "已拒絕",
    approved: "已批准",
    updatedAt: "最後更新時間",
    submitted: "未處理",
    confirmed: "待領取",
    delivered: "已領取",
    cancelled: "已取消",
    paid: "Paid",
    unpaid: "Unpaid",
    inStock: "Normal",
    lowStock: "Low in Stock",
    outOfStock: "Out of Stock",
    takenDown: "Taken Down",
  },
  Menu: {
    Home: "主頁",
    Admins: "管理員",
    PointManagement: "分數管理",
    RiskReport: "風險報告",
    RiskManagement: "風險報告",
    UserGroup: "用戶群組",
    RewardsPoint: "積分獎勵",
    SafetyPoint: "安全記分",
    Users: "用戶管理",
    SystemOptions: "系統設定",
    Certificate: "證書審核",
    SiteAndZone: "工地和區域",
    Courses: "課程資料",
    UserDetail: "用戶資料",
    UserPoint: "用戶分數",
    ZonePoint: "區域分數",
    MyAccount: "個人檔案",
    Test: "Test",
    ContentUpdate: "Content Update",
    SafetyReason: "記分原因",
    SubContractor: "SubContractor",
    TotalOf: "共有",
    NumberOfSearchResults: "項搜尋結果",
    Tags: "Tags",
    Report: "Reporting",
    TrainingReport: "訓練報告",
    PointMart: "Point Mart",
    StoreManagement: "Store Management",
    StoreManagementDetail: "Product List",
    ProductDetail: "Product",
    OrderManagement: "Order Management",
    OrderManagementDetail: "Order List",
    OrderDetail: "Order Detail",
    ProductCategory: "Product Category",
  },
  Auth: {
    welcome: "歡迎回來！",
    login: "登入",
    forgotPwd: "忘記密碼",
    tnc: "登錄即表示您同意我們的使用條款並接收電子郵件、更新並確認您已閱讀我們的隱私政策。",
  },
  Home: {
    welcome: "歡迎來到Build King。",
  },
  Users: {
    id: "ID",
    name: "英文姓名",
    chineseName: "中文姓名",
    phone: "電話號碼",
    code: "編號",
    role: "職位",
    viewPoint: "查看積分及記分",
    displayCode: "展示QR code",
    qrCode: "用戶二維碼",
    basicInfo: "基本資料",
    lastName: "姓氏（英）",
    firstName: "名字（英）",
    lastNameZh: "姓氏（中）",
    firstNameZh: "名字（中）",
    hkid: "身分證號碼",
    workInfo: "工作資料",
    userId: "用戶ID",
    status: "用戶狀態",
    subContractor: "承包商",
    cistc: "綠卡資料",
    cwrc: "建造業工人註冊證資料",
    hkie: "香港工程師學會證",
    hkicm: "香港營造師學會証",
    refNumber: "證件號碼",
    cardName: "姓名",
    memberShip: "Membership Class",
    dateOfCompletion: "完成課程日程",
    validFrom: "有效日期由",
    validTo: "有效日期至",
    dateOfIssue: "簽發日期",
    siteInfo: "所屬工地",
    expandAll: "展開全部",
    addZone: "新增工地",
    allZone: "全部工地",
    zone: "所屬工地",
    updatedAt: "最後更新時間",
    createdAt: "建立用戶時間",
    username: "登入郵箱",
    password: "密碼",
    confirmPassword: "確認密碼",
    changePassword: "更改密碼",
    currentPassword: "現有密碼",
    newPassword: "新密碼",
    authInfo: "登入資料",
    phoneChecking: "填入電話號碼",
    roleSelection: "選擇職位",
    infoSetting: "填入基本資料",
    position: "Position",
    dateOfBirth: "Date Of Birth",
    gender: "Gender",
    f: "Female",
    m: "Male",
    preferredLanguage: "Preferred Language",
    userNotFound: "User not found. User must send an otp in the app first.",
    userExist:
      "The user has already registered. Please enter the correct phone number.",
    selectCard: "請選擇你持有的證件",
    cardType: "Card Type",
    en: "English",
    zh: "繁體中文",
    zhCHS: "简体中文",
    phoneExist: "The phone number has already been registered.",
    SubContractor: "SubContractor",
    Site: "Construction Site (For filter)",
    nameEn: "Name (English)",
    nameZh: "Name (Chinese)",
    dateOfRegistration: "Date of Registration",
    hkis: "Hong Kong Institute of Surveyors",
    hkila: "Hong Kong Institute of Landscape Architects",
    hkia: "Hong Kong Institute of Architects",
  },
  RiskReport: {
    id: "報告ID",
    title: "標題",
    location: "發現地區",
    createdAt: "報告日期",
    reportInfo: "報告資料",
    status: "狀態",
    remarks: "跟進描述",
    constructionSite: "有關工地",
    description: "描述",
    reporter: "報告者",
    handler: "處理者",
    updatedAt: "最後更新",
    pointItem: "Point Item",
    point: "Point",
    approvedPointItem: "Approved Point Item",
  },
  Role: {
    unknown: "未知",
    cmsAdmin: "文書員工",
    siteAdmin: "地盤行政人員",
    manager: "管理人員",
    safetyAdmin: "安全人員",
    worker: "工友",
    frontline: "前線人員",
    external: "External",
    guest: "Guest",
    otherOfficeStaff: "其他文職職員",
  },
  SiteConfig: {
    site: "工地",
    zone: "區域",
  },
  Courses: {
    name: "課程標題",
    nameEn: "課程標題（英）",
    nameZh: "課程標題（中）",
    credit: "記分",
    offset: "No. of point offset",
    pointItemName: "PointItem Name",
    pointItemType: "變動類型",
    pointItemValue: "變動值",
    course: "Course",
    training: "Training",
    category: "Type",
  },
  SafetyReason: {
    reason: "原因",
    point: "記分值",
    category: "類別",
    reasonEn: "Reason (Eng)",
    reasonZh: "Reason (Chi)",
    pointNumber: "No. of Point",
    value: "變動值",
    type: "類型",
    safetyPoint: "安全記分",
    rewardPoint: "獎勵積分",
    credit: "減值 (-)",
    debit: "加值 (+)",
    priority: "Priority",
  },
  Certificate: {
    title: "證書標題",
    user: "申請人",
    type: "證書類型",
    id: "證書ID",
    createdAt: "報告日期",
    phone: "電話",
    certificate: "證書",
    reporter: "報告者",
    description: "描述",
    reason: "審核描述",
    remarks: "跟進描述",
    status: "狀態",
    constructionSite: "工地",
  },
  PointManagement: {
    User: "個人",
    Zone: "區域",
    Site: "工地",
    name: "英文姓名",
    chineseName: "中文姓名",
    phone: "電話號碼",
    userId: "用戶ID",
    zoneName: "區域",
    zoneId: "區域ID",
    safetyPoint: "記分值",
    reason: "原因",
    rewardPoint: "獎分值",
    FilterBySiteOrZone: "按工地或區域篩選",
    total: "全部",
    scoreForSelectedArea: "已選區域合記分數",
    points: "分",
    inspectUserProfile: "查看用戶檔案",
    belongingConstructionSite: "所屬工地",
    title: "標題",
    siteDiscovered: "發現地區",
    createdAt: "建立日期",
    description: "描述",
    tabSafety: "安全記分",
    tabReward: "獎勵記分",
    none: "無",
    good: "良好",
    slight: "輕微",
    moderate: "中等",
    severe: "嚴重",
    severity: "記分",
    credit: "記分",
    onlyLast12Months: "只記錄過去十二個月的記分",
    rewardPoints: "獎勵積分",
    modalSafetyPoints: "安全記分",
    modalRewardPoints: "獎勵積分",
    add: "新增",
    formReason: "記分原因",
    formValue: "記分值",
    formDescription: "描述",
    formZone: "區域",
    formSite: "工地",
  },
  SubContractor: {
    nameEn: "Name (EN)",
    nameZh: "Name (ZH)",
    name: "Name",
  },
  ContentUpdate: {
    en: "英文",
    zh: "中文",
    zhCHS: "簡體中文",
    page: "頁面",
    tnc: "條款和條件",
    pp: "隱私政策",
  },
  Validation: {
    required: "Required",
    invalidPhone: "Invalid Phone Number",
  },
  Tag: {
    type: "Type",
    value: "Value",
    name: "Name",
    Ref: "Ref",
    refType: "Ref Type",
    constructionSite: "Construction Site",
  },
  Report: {
    rewardPointTransaction: "Daily Merit Point Download",
    safetyPointTransaction: "Daily Safety Point Download",
    from: "From",
    to: "To",
  },
  StoreManagement: {
    name: "Site Name",
    siteCode: "Site Code",
    point: "No. of Point Required",
    lowInStock: "Low Stocks Alert",
    availableStock: "Available Stock",
    reservedStock: "Reserved Stock",
    Medias: "Photos",
    availableFrom: "Valid From",
    availableTo: "Valid To",
    basic: "Basic Info",
    stock: "Stock Info",
    media: "Photos",
    SKUID: "Product Code",
    nameEn: "Name (Eng)",
    nameZh: "Name (Chi)",
    nameZhCHS: "Name (CHS)",
    descriptionEn: "Description (Eng)",
    descriptionZh: "Description (Chi)",
    descriptionZhCHS: "Description (CHS)",
    unitEn: "Unit (Eng)",
    unitZh: "Unit (Chi)",
    unitZhCHS: "Unit (CHS)",
    siteId: "Site ID",
    productId: "Product ID",
    totalStock: "Total Stock",
    remark: "Remark",
    remarkEn: "Remark (Eng)",
    remarkZh: "Remark (Chi)",
    remarkZhCHS: "Remark (CHS)",
    store: "Store",
    changeAvailableStock: "Change Stock",
    add: "Add Stock (+)",
    reduce: "Reduce Stock (-)",
    type: "Type",
    expectedAvailableStock: "Expected Available Stock",
    unlimited: "Unlimited",
    productName: "Name",
    productCategory: "Product Category",
  },
  OrderManagement: {
    name: "Name",
    nameEn: "Name (Eng)",
    nameZh: "Name (Chi)",
    orderRef: "Order Reference Number",
    inspectUserProfile: "Go to Profile",
    displayOrderCode: "Display Order Code",
    basicInfo: "Basic Information",
    cartInfo: "Cart Information",
    productName: "Product Name",
    quantity: "Quantity",
    phoneNumber: "Phone Number",
    orderId: "Order ID",
    orderStatus: "Order Status",
    paidStatus: "Paid Status",
    user: "User",
    siteName: "Site Name",
    totalPoints: "Total Points",
    amount: "Amount",
    createdAt: "Created At",
    updatedAt: "Last Updated",
    remarks: "Remarks",
    redemptionPhoto: "Redemption Photo",
  },
  ProductCategory: {
    nameEn: "Name (Eng)",
    nameZh: "Name (Chi)",
    nameZhCHS: "Name (CHS)",
    code: "Code",
    descriptionEn: "Description (Eng)",
    descriptionZh: "Description (Chi)",
    descriptionZhCHS: "Description (CHS)",
  },
};
